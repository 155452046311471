<template>
  <section class="college-item" :class="{reverse: reverse}">
    <div class="text-left">
      <img :src="article.leftImage" :alt="article.title">
      <h2 class="title">{{article.title}}</h2>
      <p class="description">{{article.description}}</p>
      <p class="news-text-main"
         v-for="(text, index) in article.texts"
         :key="index">{{text}}</p>
    </div>

    <div class="images" ref="images">
      <div class="button" @click="next"></div>
      <div class="image-frame"
             v-for="(item, index) in images"
             :key="item.id" :class="{sleep: index > 2, 'image-animation': hasClick}">
          <!--上面动态绑定动画类image-animation，避免一打开页面就显示不该显示的动画-->
          <!--类sleep：除前三张图片以外的图片保持渲染状态且隐藏-->
<!--          <img :src="item.src"-->
<!--               :alt="article.title"-->
<!--               class="image">-->
          <div class="image" :style="{backgroundImage: `url('${item.src}')`}"></div>
        </div>
    </div>

  </section>
</template>

<script>
  export default {
    name: "CollegeItem",
    data() {
      return {
        images: this.article.images, // Array
        isSwitching: false,
        hasClick: false
      }
    },
    props: {
      article: {
        title: String,
        description: String,
        texts: Array,
        images: Array,
        leftImage: Object,
      },
      // 是否翻转图片和文字的位置
      reverse: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      // 查看下一张图片
      next() {
        if(!this.hasClick) this.hasClick = true // 第一次点击绑定动画
        if(this.isSwitching) return // 动画过程中不能再次点击，否则会出现画面问题
        this.isSwitching = true
        this.images.push(this.images.shift())
        setTimeout(() => {
          this.isSwitching = false
        }, 1000)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .college-item {
    height: 500px;
    margin: 0 150px;
    position: relative;
  }

  .text-left {
    width: calc((100% - 300px) / 2);
  }

  // seo隐藏
  .title,
  .description {
    text-indent: -9990px;
    position: absolute;
    top: 0;
  }

  .news-text-main {
    margin-top: 10px;
  }

  .images {
    position: absolute;
    top: 0;
    right: 0;
    height: 496px;
    width: 849px;
  }

  // 图片外框架
  .image-frame {
    overflow: hidden;
    position: absolute;
    background: #ffffff;
    box-shadow: -5px 5px 5px #aaaaaa;
    transition: all 1s;
    .image {
      display: block;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
    }
    &:nth-child(2) {
      width: 709px;
      height: 496px;
      right: 140px;
      top: 0;
      z-index: 10;
      .image {
        opacity: 1;
      }
    }
    &:nth-child(3) {
      width: 652px;
      height: 456px;
      right: 70px;
      z-index: 9;
      top: 20px;
      .image {
        opacity: 0.6;
      }
    }
    &:nth-child(4) {
      width: 576px;
      height: 416px;
      right: 0;
      z-index: 8;
      top: 40px;
      .image {
        opacity: 0.6;
      }
    }
  }
  // 绑定动画
  .image-animation {
    &:last-child {
      animation: first-item 1s;
    }
  }

  // 切换图片的隐藏按钮
  .button {
    height: 100%;
    width: 140px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 50;
    cursor: pointer;
  }

  // 第一张图片退场动画
  @keyframes first-item {
    0% {
      width: 709px;
      height: 496px;
      right: 140px;
      top: 0;
      transform: scale(1);
      z-index: 11;
      opacity: 1;
    }
    100% {
      width: 709px;
      height: 496px;
      right: 140px;
      top: 0;
      z-index: 11;
      opacity: 0;
      transform: scale(0);
    }
  }
  // 第一张图片退场动画：翻转模式
  @keyframes first-item__reverse {
    0% {
      width: 709px;
      height: 496px;
      left: 140px;
      top: 0;
      transform: scale(1);
      z-index: 11;
      opacity: 1;
    }
    100% {
      width: 709px;
      height: 496px;
      left: 140px;
      top: 0;
      z-index: 11;
      opacity: 0;
      transform: scale(0);
    }
  }
  // 除前三张图片外的图片隐藏
  .sleep {
    width: 709px;
    height: 496px;
    right: 140px;
    top: 0;
    z-index: 8;
    opacity: 0;
    transform: scale(0);
  }
  // 翻转图片和文字
  .reverse {
    .text-left {
      float: right;
    }
    .images {
      left: 0;
    }
    .image-frame {
      box-shadow: 5px 5px 5px #aaaaaa;

      &:nth-child(2) {
        left: 140px;
      }
      &:nth-child(3) {
        left: 70px;
      }
      &:nth-child(4) {
        left: 0;
      }
    }
    .button {
      left: 0;
    }
    .image-animation {
      &:last-child {
        animation: first-item__reverse 1s;
      }
    }
  }
</style>
