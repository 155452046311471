<template>
  <!--三大学院-->
  <main class="college clear-before">
    <college-item :article="articles[0]"/>
    <college-item :article="articles[1]" :reverse="true"/>
    <college-item :article="articles[2]"/>

  </main>
</template>

<script>
  import CollegeItem from "./childComp/CollegeItem";

  export default {
    name: "College",
    data() {
      return {
        articles: [
          {
            title: '财商导师学院',
            description: '我们拥有实战经验丰富的强大财商导师团队，形成了完备的导师培训体系，致力于培育更多优秀的少年财商导师。',
            leftImage: require('@/assets/img/main/tutor@2x.png'),
            texts: [
              '国内唯一一家同时拥有环球专家导师与国内专家导师团队的学院。财商导师学院的导师们拥有金融分析师、注册会计师、国际金融理财师、金融理财师、国家理财规划师等专业资格。成立至今在全国理财师大赛中获得“全国十佳理财师团队” 和“个人全国十佳理财师”荣誉，更凭借四个专业服务标准荣获“2012年度中国理财行业突出贡献奖”“2015-2016年度中国理财行业服务创新奖”',
              '经过13年的沉淀，学院拥有全国数量最多的获国家初、中、高级职称的理财规划导师团队，高级职称超过百人，其中诞生了行内许多顶尖的实践派财商导师，受到金融界、教育界的高度赞誉，许多导师在全国高等院校授课，受聘为创业成长导师，在全国的初、高中学校授课，获最佳校园财商教育导师称号，也有在网络上拥有数百万粉丝的网红财商导师等。'
            ],
            images: [
              {id: 'a1', src: require('@/assets/img/college/college1-2.jpg')},
              {id: 'a2', src: require('@/assets/img/college/college1-1.jpg')},
              // {id: 'a3', src: require('@/assets/img/college/college_tutor_1.jpg')},
              {id: 'a4', src: require('@/assets/img/college/college_tutor_2.jpg')},
              {id: 'a5', src: require('@/assets/img/college/daoshi1.jpg')},
              {id: 'a6', src: require('@/assets/img/college/daoshi2.jpg')},
            ]
          },
          {
            title: '财商少年学堂',
            description: '财商少年学堂与家庭一起打造孩子终身学习成长平台，开发了一系列的家庭亲子理财课程与活动。',
            leftImage: require('@/assets/img/main/children@2x.png'),
            texts: [
              '进入校园，打造“未来CEO”财商社团品牌是孵化未来CEO的基地,是记录孩子与家庭成长的创意平台。 每年举办的全国现金流游戏大赛，在全国初中，高中举办模拟创业比赛，携手银行，证券，基金等成立财商少年投融资模拟平台 。',
              '我们将联合国内外知名高校开发一系列财商主题的游学之旅。 建立少儿文化创意产业平台，让孩子们使用先进技术学习，创立财商少年大社区，实现资源的优化流动。'
            ],
            images: [
              {id: 'b1', src: require('@/assets/img/college/child1.jpg')},
              {id: 'b2', src: require('@/assets/img/college/child2.jpg')},
              {id: 'b3', src: require('@/assets/img/college/child3.jpg')},
              {id: 'b4', src: require('@/assets/img/college/child4.jpg')},
              {id: 'b5', src: require('@/assets/img/college/child5.jpg')},
            ]
          },
          {
            title: '父母学堂',
            description: '我们深知财商教育过程中，父母的重要性，从而开发打造了丰富的父母成长配套课程',
            leftImage: require('@/assets/img/main/parents@2x.png'),
            texts: [
              '包括成人家庭理财课程，打造父母的榜样教育，重建家庭正确财商思维，形成优秀的习惯，从而影响家庭理财行为，持续改善自身形成家庭的成长氛围，最终转化为优秀的家族财商传承文化。 创新地引导父母与孩子一起参与比赛，学会一起规划，共同设置家庭成长基金，使财商教育成为家族必备技能。',
              '鼓励父母争当财商大使，进入大学/中学/小学，以及各种教育机构，去普及财商教育。',
              '与父母一起建立家庭成长档案，终身跟踪优化，使每个家族受益成为现实。'
            ],
            images: [
              {id: 'c1', src: require('@/assets/img/college/college_parent_1.jpg')},
              {id: 'c2', src: require('@/assets/img/college/college_parent_2.jpg')},
              {id: 'c3', src: require('@/assets/img/college/college_parent_3.jpg')},
              {id: 'c4', src: require('@/assets/img/college/college_parent_4.jpg')},
              {id: 'c5', src: require('@/assets/img/college/college_parent_5.jpg')},
            ]
          }

        ]
      }
    },
    components: {
      CollegeItem
    },
    metaInfo: {
      title: '中国财商少年官网-财商学院',
      meta: [
        {
          name: 'keyWords',
          content: '财商,财商导师学院,财商少年学堂,财商父母课堂,财商学院,财商少年,中国财商少年,财商少年三大学院,财商导师培训,亲子教育'
        },
        {
          name: 'description',
          content: '中国财商少年财商学院拥有实战经验丰富的强大财商导师团队，完备的导师培训体系，致力于培养优秀的财商导师，为孩子和家庭打造终身学习成长平台，开发了一系列家庭亲子理财课程与活动，也为父母设计了成长配套课程， 与您的孩子一同成长。'
        }
      ]
    }
  }
</script>

<style lang="scss" scoped>
  .college-item:nth-child(1) {
    margin-top: 100px;
  }

  .college-item:nth-child(2) {
    margin-top: 125px;
  }

  .college-item:nth-child(3) {
    margin-top: 125px;
    margin-bottom: 100px;
  }

</style>
